import React, {useState, useEffect, useRef} from 'react';
import auth0Client from "../Auth/Auth"
import {Table, Spinner} from 'react-bootstrap';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import config from "../config";
import Sort from "./Sort";


const MostPopularTable = (props) => {
   
    const [isLoading, setIsLoading] = useState(true);
    const controllerRef = useRef(new AbortController());
    const [dates, setDates] = useState({startDate: props.date.startDate, endDate: props.date.endDate});
    const [sellerAccount, setSellerAccount] = useState(props.sellerAccountId);
    const [data, setData] = useState([]);
    const [sortColumn, setSortColumn] = useState('quantity');
    const [sortDirection, setSortDirection] = useState('desc');
    const [isSorting, setIsSorting] = useState(true);

    useEffect(() => {
        setDates({startDate: props.date.startDate, endDate: props.date.endDate});
        getMostPopular()

        return () => {
            controllerRef.current.abort();
        }
    },[props.date])


    useEffect(() => {

        getMostPopular()


    },[sortDirection, sortColumn])

    const getMostPopular = async () => {

        setIsSorting(true);
        try {
          let result = await axios.get(`${config.api.BASE_URL}/metrics/most_popular`, {
              params: {start: dates.startDate, end:dates.endDate, sort: sortColumn+" "+sortDirection },
              headers: { 'Authorization': `Bearer ${auth0Client.getIdToken()}`, 'shopdeploy-acc': sellerAccount}, signal: controllerRef.current.signal });
            setData(result.data.mostPopular)
            setIsLoading(false)
            setIsSorting(false);
      }
      catch (error) {
          console.log(error)
      }
    }

    const handleDirectionChange = (result) => {

        setSortDirection(result)
        // this.setState({direction: v,  pageNum: 0}, () => {
        //     this.getInventory()
        // });
    }

    const handleSortChange =(result) => {


        setSortColumn(result)

    }

    const sortOptions =[
        {value: 'title', label: 'Title'},
        {value: 'quantity', label: 'Quantity'},
        {value: 'revenue', label: 'Revenue'},
        {value: 'avg_price', label: 'Avg Selling Price'},

    ]


    const RenderFilters = () => {

        return(
            <Sort
                options={sortOptions}
                selected={sortColumn}
                direction={sortDirection}
                callback={handleSortChange}
                directionCallback={handleDirectionChange}
            />
        )
    }

    const RenderTable = () => {
        return(<>

    <Table striped bordered hover size="sm" className={isSorting && 'loading'}>
    <thead>
    <tr>
        <th>Product Title</th>
        <th>Quantity</th>
        <th>Avg Selling Price</th>
        <th>Total Revenue</th>
    </tr>
    </thead>
        <tbody>
      {
        (data).map(
          (item, i) =>
              <tr key={i}>
                  {/* <td> <Link to={`/offers/${item.id}`}>{item.title}</Link></td>   */}
                  <td>{item.title}</td>
                  <td>{item.totalQuantity}</td>
                  <td><NumberFormat value={item.avgPrice} displayType={'text'} decimalScale={2} fixedDecimalScale thousandSeparator={true} prefix={'R'}/>
                  </td>
                  <td><NumberFormat value={item.revenue} displayType={'text'} thousandSeparator={true} prefix={'R'}/>
                  </td>
              </tr>
        )}
    </tbody>
    </Table>
            </>
        )
    }

    const RenderDisplay = () => {
        return(
            <>
                <RenderTable/>
            </>
        )
    }

return(<div className={'mt-3'}>

    <div className={'d-flex mb-3'}>
        <h4>Top sellers</h4>
        <div className={'ms-auto align-items-center d-flex me-2 gap-3'}>  <RenderFilters /> </div>
    </div>


            {!isLoading? <RenderDisplay />
                 :

              <div className="d-flex justify-content-center">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
              </div>

        }

        </div>)
}
export default MostPopularTable;

